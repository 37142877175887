import React from "react";

export default function ConductCode() {
  return (
    <div className="flex-auto px-10 md:px-14 xl:px-20 bg-gray-200 py-5 xl:py-10">
      <p className="text-center text-3xl sm:text-6xl mt-5 xl:mt-0">Código de Conducta</p>
      <p className="sm:text-xl mt-5">
        λ Club es un espacio creado por estudiantes y para estudiantes, en donde el respeto y la inclusión son
        aspectos de vital importancia. Es con esto en mente que esperamos que todos los miembros se conduzcan
        de una manera que refleje nuestros valores, tanto dentro de los espacios directamente ligados a λ Club
        como en cualquier otra situación externa que pueda afectar la imagen del club u ofender a alguno de
        sus miembros. Recuerda que nuestro futuro como comunidad depende de los nuevos integrantes, y con ese
        fin deseamos ser una asociación con buena reputación.
      </p>
      <p className="text-lg sm:text-2xl mt-5 pb-1 border-b-2 border-zinc-900 font-medium">
        Conductas Positivas
      </p>
      <p className="sm:text-xl mt-2">
        Los siguientes son algunas de las actitudes que fomentamos y apreciamos en los miembros de nuestra
        comunidad:
      </p>
      <ul className="list-disc list-inside">
        <li className="sm:text-lg mt-0.5">
          Total respeto a todas las personas, especialmente ante opiniones e ideologías distintas a las
          propias.
        </li>
        <li className="sm:text-lg mt-0.5">Participación entusiasta en las actividades de la comunidad.</li>
        <li className="sm:text-lg mt-0.5">Dar y aceptar retroalimentación constructiva y empática.</li>
        <li className="sm:text-lg mt-0.5">Compañerismo, cooperación y apoyo mutuo.</li>
        <li className="sm:text-lg mt-0.5">Atención a las necesidades de los miembros de la comunidad.</li>
        <li className="sm:text-lg mt-0.5">Apreciación por la diversidad, y responsabilidad social.</li>
      </ul>
      <p className="text-lg sm:text-2xl mt-5 pb-1 border-b-2 border-zinc-900 font-medium">
        Conductas Negativas
      </p>
      <p className="sm:text-xl mt-2">De la misma manera, algunas conductas que no serán toleradas son:</p>
      <ul className="list-disc list-inside">
        <li className="sm:text-lg mt-0.5">
          Faltas de respeto, incluyendo pero sin limitarse a, hostigación o acoso, uso de lenguaje grosero u
          obsceno, violencia de cualquier tipo (verbal, física, sexual, emocional), denigración, entre otros.
        </li>
        <li className="sm:text-lg mt-0.5">
          Racismo o discriminación de cualquier tipo, incluyendo motivos como sexo, género, edad, apariencia
          física, nacionalidad, ideología política, religión, clase social o económica, entre otros.
        </li>
        <li className="sm:text-lg mt-0.5">
          Acoso y violencia sexual, uso de lenguaje o imágenes sexuales, vestimenta sexualmente sugestiva.
        </li>
        <li className="sm:text-lg mt-0.5">
          Faltas legales. Estas incluyen el plagio, difamación, pornografía infantil, uso de sustancias
          ilegales, entre otros.
        </li>
        <li className="sm:text-lg mt-0.5">
          Spam en los medios digitales, como redes sociales y reuniones de la comunidad.
        </li>
      </ul>
      <p className="sm:text-xl mt-1">
        Recordemos que en nuestra comunidad hay menores de edad, por lo que debemos procurar mantener un
        lenguaje y actitud adecuada. Además, cualquier otra situación que pueda resultar ofensiva o indebida
        en un ambiente profesional no será tolerada.
      </p>
      <p className="sm:text-xl mt-1">
        Estas medidas son aplicables dentro de las plataformas oficiales de la comunidad como lo son redes
        sociales, servidor de Discord, reuniones en servicios de videoconferencias, espacios físicos usados
        por la comunidad, entre otros. Igualmente aplican siempre que un miembro de la comunidad se encuentre
        representando a λ Club, o cuando de alguna forma pueda ser ligado a la imagen pública del club.
      </p>
      <p className="text-lg sm:text-2xl mt-5 pb-1 border-b-2 border-zinc-900 font-medium">
        ¿Qué realizar si soy víctima o testigo de alguna conducta inadecuada?
      </p>
      <p className="sm:text-xl mt-2">
        Los Líderes de la Comunidad tenemos como prioridad el bienestar de todos los miembros de λ Club, y
        como tal nos hacemos responsables de hacer cumplir este código de conducta, y de tomar las medidas
        adecuadas en caso de que haya un suceso que atente contra nuestros valores.
      </p>
      <p className="sm:text-xl mt-2">
        Con esta finalidad te pedimos de la manera más atenta que, si eres víctima de alguna situación
        relacionada al incumplimiento del código de conducta o eres testigo de ello, lo hagas saber a los
        Líderes de la Comunidad.
      </p>
      <p className="sm:text-xl mt-2">Puedes realizarlo utilizando alguno de los siguientes canales:</p>
      <ul className="list-disc list-inside">
        <li className="sm:text-lg mt-0.5">
          Canal <span className="italic font-semibold text-cyan-500">#reportes</span> en el servidor de
          Discord de la comunidad.
        </li>
        <li className="sm:text-lg mt-0.5">
          Utilizando la etiqueta <span className="italic font-semibold text-cyan-500">@Community Leader</span>{" "}
          en el servidor de Discord de la comunidad.
        </li>
        <li className="sm:text-lg mt-0.5">
          Contactando por mensaje privado a alguna de las personas listadas como{" "}
          <span className="italic font-semibold text-cyan-500">@Community Leader</span> en el servidor de
          Discord de la comunidad.
        </li>
        <li className="sm:text-lg mt-0.5">
          Enviando un correo a{" "}
          <a href="mailto:jpyamamoto@ciencias.unam.mx" className="italic font-semibold text-cyan-500">
            jpyamamoto@ciencias.unam.mx
          </a>{" "}
          dirigido a Juan Pablo Yamamoto, quien es uno de los Líderes de la comunidad.
        </li>
      </ul>
      <p className="sm:text-xl mt-1">
        Recordemos que en nuestra comunidad hay menores de edad, por lo que debemos procurar mantener un
        lenguaje y actitud adecuada. Además, cualquier otra situación que pueda resultar ofensiva o indebida
        en un ambiente profesional no será tolerada.
      </p>
      <p className="sm:text-xl mt-1">
        Estas medidas son aplicables dentro de las plataformas oficiales de la comunidad como lo son redes
        sociales, servidor de Discord, reuniones en servicios de videoconferencias, espacios físicos usados
        por la comunidad, entre otros. Igualmente aplican siempre que un miembro de la comunidad se encuentre
        representando a λ Club, o cuando de alguna forma pueda ser ligado a la imagen pública del club.
      </p>
      <p className="text-lg sm:text-2xl mt-5 pb-1 border-b-2 border-zinc-900 font-medium">
        ¿Qué sucederá si incumplo el código de conducta?
      </p>
      <p className="sm:text-xl mt-2">
        Como parte del proceso de moderación, los líderes nos reservamos el derecho de eliminar o editar
        contenido publicado en las plataformas de la comunidad, para asegurar el cumplimiento del código de
        conducta. Si se considera apropiado y necesario, comunicaremos los motivos que llevaron a tal
        decisión.
      </p>
      <p className="sm:text-xl mt-2">
        Algunas de las posibles consecuencias de incumplir las normas, serán la expulsión de la comunidad y
        prohibición de acceso a los eventos y medios digitales de la comunidad.
      </p>
      <p className="sm:text-xl mt-2">
        En caso de ser necesario, los líderes de la comunidad también daremos seguimiento al caso utilizando
        vías legales.
      </p>
      <div className="flex justify-center">
      <div className="w-1/4 border-b-4 border-black mt-6 mb-4"/>
      </div>
      <p className="sm:text-xl mt-2">
        Los líderes de la comunidad nos reservamos el derecho de modificar el presente código de conducta
        según consideremos apropiado en busca del bien común de todos los miembros, en cuyo caso, tales
        modificaciones serán comunicadas por medios oficiales.
      </p>
      <p className="sm:text-xl mt-2">
        Si tienes dudas sobre el código de conducta, puedes acercarte a los líderes de la comunidad utilizando
        los medios antes listados y estaremos gustosos de realizar cualquier aclaración.
      </p>
      <p className="sm:text-xl mt-2">
        Por una comunidad sana, reiteramos nuestra petición a todos los miembros de la comunidad a seguir las
        normas anteriores, y agradecemos a todos quienes se apegan al código de conducta. Procuremos un
        ambiente agradable para todos.
      </p>
    </div>
  );
}
