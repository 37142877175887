import React, { Fragment } from "react";

export default function SessionAvailable({ isLive, link }: SessionAvailableProps) {
  return (
    <div className="filter pl-4 pb-2 pt-4 text-base leading-6 font-medium sm:text-lg sm:leading-7">
      {isLive ? (
        <p>
          <a href={link} className="text-blue-400 hover:text-blue-500">
            Registrarse al evento &rarr;
          </a>
        </p>
      ) : (
        <Fragment />
      )}
    </div>
  );
}
