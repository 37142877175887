const questions: Question[] = [
  {
    question: "¿Cuál es el costo de los eventos?",
    answer: "¡Ninguno!, son completamente gratis",
  },
  {
    question: "¿Cómo puedo participar en los eventos?",
    answer:
      "En la sección de eventos, da click al evento deseado y te redirigirá a un formulario de inscripción",
  },
];

export default questions;
