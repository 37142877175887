import React from "react";
import GithubCampus from "../img/github-campus-expert.png";
import MLSA from "../img/mlsa.png";
import GDG from "../img/gdg.png";
import Azure from "../img/azure.png";
import Hackademy from "../img/hackademy.png";
import Hackwomen from "../img/hackwomen.png";
import SG from "../img/sg.svg";

export default function Collaborators() {
  return (
    <div className="flex flex-col flex-auto px-10 md:px-14 xl:px-20 bg-gray-200 py-5 xl:py-10">
      <p className="text-center text-3xl sm:text-6xl mt-5">¡Conoce a nuestros colaboradores!</p>
      <p className="text-center text-lg sm:text-2xl mt-5">
        Agradecemos a las siguientes organizaciones por su apoyo
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 items-center mt-10 sm:mt-16 gap-y-8 md:gap-y-12 gap-x-4 md:gap-x-6">
        <a
          href="https://githubcampus.expert/"
          target="_blank"
          className="justify-self-center"
          rel="noreferrer"
        >
          <img src={GithubCampus} alt="Github Campus Expert" className="w-auto max-h-40" />
        </a>
        <a href="https://studentambassadors.microsoft.com/" target="_blank" rel="noreferrer" className="justify-self-center">
          <img src={MLSA} alt="Microsoft Learn Student Ambassadors" className="w-auto max-h-40" />
        </a>
        <a href="https://developers.google.com/community/gdg" target="_blank" rel="noreferrer" className="justify-self-center">
          <img src={GDG} alt="Google Developer Groups" className="w-auto max-h-40" />
        </a>
        <a
          href="https://developer.microsoft.com/en-us/azure-tech-groups/overview/"
          target="_blank"
          rel="noreferrer"
          className="justify-self-center"
        >
          <img src={Azure} alt="Azure Tech Groups" className="w-auto max-h-40" />
        </a>
        <a href="https://hackademy.lat/" target="_blank" rel="noreferrer" className="justify-self-center">
          <img src={Hackademy} alt="Hackademy" className="w-auto max-h-40" />
        </a>
        <a href="https://hackwomen.dev/" target="_blank" rel="noreferrer" className="justify-self-center">
          <img src={Hackwomen} alt="Hack women" className="w-auto max-h-40" />
        </a>
        <a href="https://sg.com.mx/" target="_blank" rel="noreferrer">
          <img src={SG} alt="Software Guru" className="w-auto max-h-40 justify-self-center" />
        </a>
      </div>
    </div>
  );
}
