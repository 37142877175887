const resources: Resource[] = [
  {
    eventTitle: "Taller de preparación AZ-900",
    links: [
        { title: "Microsoft Learn", link: "https://docs.microsoft.com/es-es/learn/" },
        { title: "AZ-900", link: "https://docs.microsoft.com/es-es/learn/certifications/exams/az-900" },
    ],
  },
];

export default resources;
