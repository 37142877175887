import React, { useState, useEffect } from "react";
import Event from "./LambdaEvent";
import lambdaEvents from "../data/events";

/**
 * Returns a capitalized string
 * @param string String to capitalize
 */
function capitalize(string: String) {
  return string.replace(/^\w/, (c) => c.toUpperCase());
}

/**
 * Options to format a Date to string
 */
const options: Intl.DateTimeFormatOptions = {
  weekday: "long",
  day: "2-digit",
  month: "long",
};

/**
 * Returns the string in Spanish of the day from a date
 * @param date Date to format
 */

function dayToString(date: Date) {
  const [startWeekday, startDay, startPreposition, startMonth] = date
    .toLocaleDateString("es-MX", options)
    .split(" ");

  // Concatenation of Strings through an array
  const dayString = [
    capitalize(startWeekday.replace(",", "")),
    startDay,
    startPreposition,
    capitalize(startMonth),
  ].join(" ");

  return dayString;
}

/**
 * Group the events by the same day
 * @param lambdaEvents Array of the event
 * @returns An array with groups of events
 */
const groupLambdaEvents = (lambdaEvents: LambdaEvent[]) => {
  const sortedLambdaEvents = lambdaEvents.slice().sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
  let groupedLambdaEvents: LambdaEventGroup[] = [];
  for (const lambdaEvent of sortedLambdaEvents) {
    if (groupedLambdaEvents.length === 0) {
      groupedLambdaEvents.push({ date: lambdaEvent.startDate, lambdaEvents: [lambdaEvent] });
    } else {
      const lastDate = groupedLambdaEvents[groupedLambdaEvents.length - 1].date;
      if (
        lambdaEvent.startDate.getDate() === lastDate.getDate() &&
        lambdaEvent.startDate.getMonth() === lastDate.getMonth() &&
        lambdaEvent.startDate.getFullYear() === lastDate.getFullYear()
      ) {
        groupedLambdaEvents[groupedLambdaEvents.length - 1].lambdaEvents.push(lambdaEvent);
      } else {
        groupedLambdaEvents.push({ date: lambdaEvent.startDate, lambdaEvents: [lambdaEvent] });
      }
    }
  }
  return groupedLambdaEvents;
};

export default function Agenda() {
  const lambdaEventsGroup = groupLambdaEvents(lambdaEvents);
  const [actualDate, setActualDate] = useState(new Date());
  useEffect(() => {
    let intervalID = setInterval(() => setActualDate(new Date()), 60000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);
  return (
    <div className="px-5 bg-gray-200 flex-auto">
      {lambdaEventsGroup.map(({ date, lambdaEvents }, indexDate) => (
        <div key={indexDate}>
          <h3 className="mt-6 text-2xl font-semibold text-center mb-3">{dayToString(date)}</h3>
          <div className="flex flex-wrap flex-row justify-center">
            {lambdaEvents.map((lambdaEvent, index) => (
              <Event
                key={index}
                image={lambdaEvent.image}
                title={lambdaEvent.title}
                place={lambdaEvent.place}
                description={lambdaEvent.description}
                startDate={lambdaEvent.startDate}
                endDate={lambdaEvent.endDate}
                link={lambdaEvent.link}
                actualDate={actualDate}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
