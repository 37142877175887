import React, { Fragment } from "react";
import { LocationMarkerIcon, CalendarIcon, CodeIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import SessionAvailable from "./EventAccess";

/**
 * Options to format a Date to string
 */
const options: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "short",
  weekday: "short",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

/**
 * Returns a capitalized string
 * @param string String to capitalize
 */
function capitalize(string: String) {
  return string.replace(/^\w/, (c) => c.toUpperCase());
}

/**
 * Returns the string in 12 hrs format of the time from a date
 * @param date Date to format
 */

 function timeToString(date: Date) {
  let [startTime, startHour12] = date.toLocaleDateString("es-MX", options).split(" ").slice(-2);
  if (startTime.startsWith("00:")) {
    startTime = startTime.replace("00:", "12:");
  }
  const timeString = startTime + " " + startHour12[0].toUpperCase() + startHour12[3].toUpperCase();

  return timeString;
}

/**
 * Returns the string in Spanish of the day from a date
 * @param date Date to format
 */

function dayToString(date: Date, full = false) {
  // Options for the full date
  if (full) {
    options.weekday = "long";
    options.month = "long";
  } else {
    options.weekday = "short";
    options.month = "short";
  }

  const [startWeekday, startDay, startPreposition, startMonth] = date
    .toLocaleDateString("es-MX", options)
    .split(" ");

  // Concatenation of Strings through an array
  let dayString = [
    capitalize(startWeekday.replace(",", "")),
    startDay,
    startPreposition,
    capitalize(full ? startMonth : startMonth.replace(".", "")),
  ].join(" ");
  return dayString;
}

/**
 * Compares two dates, if they are the same it returns only a formatted date.
 * Else it returns both formatted dates and joined with an '-'
 * @param startDate Start date of the event
 * @param endDate End date of the event
 */

function formatDay(startDate: Date, endDate: Date) {
  if (
    startDate.getDate() === endDate.getDate() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear()
  )
    return dayToString(startDate, true) + "\n" + timeToString(startDate) + " - " + timeToString(endDate);
  else return dayToString(startDate) + " - " + dayToString(endDate);
}

function Event({ image, title, place, description, startDate, endDate, link, actualDate }: EventProps) {
  // Formatted Time
  const formattedStartTime = formatDay(startDate, endDate);
  // Event Date Comparations With Actual Date
  let eventBegan = startDate.getTime() - actualDate.getTime() < 600000;
  let eventIsLive = endDate.getTime() - actualDate.getTime() > -300000;
  // If its over the event, set it gray
  const grayscale = !eventIsLive && eventBegan ? "filter grayscale" : "";
  return (
    <div className="flex flex-col justify-center my-3 mx-6">
      <div className="sm:max-w-xl sm:mx-auto">
        <div className="px-2 py-3 bg-white shadow-xl rounded-3xl p-6">
          <div className="max-w-md mx-auto">
            <div className="divide-y divide-gray-400">
              <div className={`${grayscale} py-8 leading-6 space-y-4 sm:leading-7 flex justify-center`}>
                <img alt={title} src={image} className="min-w-full" />
              </div>
              <div
                className={`${grayscale} py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7`}
              >
                <ul className="list-disc space-y-2">
                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <CodeIcon className="ml-2 flex-shrink-0 h-5 w-5 text-orange-500" />
                    </span>
                    <p className="ml-4 font-extrabold uppercase">{title}</p>
                  </li>
                  <li className="flex items-center">
                    <span className="h-6 flex items-center sm:h-7">
                      <CalendarIcon className="ml-2 flex-shrink-0 h-5 w-5 text-green-500" />
                    </span>
                    <p className="ml-4 font-semibold italic whitespace-pre-line">{formattedStartTime}</p>
                  </li>
                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <LocationMarkerIcon className="ml-2 flex-shrink-0 h-5 w-5 text-red-600" />
                    </span>
                    <p className="ml-4 font-semibold italic capitalize">{place}</p>
                  </li>
                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <DotsVerticalIcon className="ml-2 flex-shrink-0 h-5 w-5 text-purple-600" />
                    </span>
                    <p className="ml-4">{description}</p>
                  </li>
                </ul>
              </div>
              {link ? (
                  <SessionAvailable isLive={eventIsLive} link={link} />
                ) : (
                  <Fragment />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Event;
