import AZ900 from "./img/AZ-900.png";
import girls_challenge from "./img/girls-challenge.jpeg";
const sessions: LambdaEvent[] = [
  {
    image: AZ900,
    title: "Curso de preparación para la certificación AZ-900",
    place: "Escuela Superior de Cómputo / En línea",
    description:
      "Esta serie de talleres te permitirá prepararte para tomar la certificación AZ-900 y sacar el mejor resultado. Durante el curso revisaremos todos los temas necesarios para la certificación, tendremos distintas didácticas que pondrán a prueba tu conocimiento y daremos distintos regalos a los participantes más activos.",
    startDate: new Date(2022, 2, 22, 13, 30),
    endDate: new Date(2022, 3, 7, 15, 0),
    link: "https://www.eventbrite.com.mx/e/curso-de-preparacion-para-la-certificacion-az-900-tickets-291514346257https://teams.microsoft.com/l/meetup-join/19%3ameeting_YTMyMzJkMGYtNWUzZi00MzJlLWE1MTEtZDk0NDEwMWM5MWJk%40thread.v2/0?context=%7b%22Tid%22%3a%2284c31ca0-ac3b-4eae-ad11-519d80233e6f%22%2c%22Oid%22%3a%22f3877055-697b-4549-ac3f-cfcd563d3efe%22%2c%22IsBroadcastMeeting%22%3atrue%7d&btype=a&role=a",
  },
  {
    image: girls_challenge,
    title: "Girls Challenge",
    place: "En línea",
    description:
      "El club de algoritmia de ESCOM las invita a participar en esta tercera edición del Girls Challenge! Habrá talleres y conferencias de mujeres en la tecnología y en la programación competitiva. Además, tendremos premios especiales por parte de nuestros patrocinadores: Microsoft y Meta.",
    startDate: new Date(2022, 2, 19, 13, 30),
    endDate: new Date(2022, 2, 21, 15, 0),
    link: "https://bit.ly/GirlsChallenge3",
  },
];

export default sessions;
