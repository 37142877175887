import React, { useState } from "react";
import Resource from "./Resource";
import resources from "../data/resources";

export default function ResourcesLibrary() {
  const [search, setSearch] = useState("");
  const resourcesFilter = resources.filter(({eventTitle})=>eventTitle.includes(search));
  return (
    <div className="px-5 bg-gray-200 flex-auto">
      <div>
        <h2 className="mt-6 text-3xl font-semibold text-center mb-3">Biblioteca de recursos</h2>
        <h2 className="mt-3 text-xl text-center mb-3">
          Aquí podrás encontrar todos los recursos que usaremos
        </h2>
        <div className="w-3/4 sm:w-2/3 md:w-1/2 mx-auto my-4">
          <input
            className="w-full py-2 px-4 text-xl rounded-lg border border-gray-300 text-gray-700 focus:outline-blue-500"
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="flex flex-wrap flex-row justify-center">
          {resourcesFilter.map((resource, index) => (
            <Resource key={index} eventTitle={resource.eventTitle} links={resource.links} />
          ))}
        </div>
      </div>
    </div>
  );
}
