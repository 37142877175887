import React from "react";
import { LinkIcon } from "@heroicons/react/solid";

function Resource({ eventTitle, links }: Resource) {
  return (
    <div className="flex flex-col justify-center my-3 mx-6">
      <div className="sm:max-w-xl sm:mx-auto">
        <div className="px-2 py-3 bg-white shadow-xl rounded-3xl p-6">
          <div className="max-w-md mx-auto">
            <div className="divide-y divide-gray-400">
              <div className="py-6 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7">
                <p className="px-4 text-center font-extrabold uppercase">{eventTitle}</p>
              </div>
              <div className="py-2 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7">
                <ul className="list-disc space-y-1">
                  {links.map((link, index) => (
                    <li className="flex items-start py-2 px-1 rounded-md hover:bg-gray-300" key={index}>
                      <span className="h-6 flex items-center sm:h-7">
                        <LinkIcon className="ml-2 flex-shrink-0 h-5 w-5 text-blue-500" />
                      </span>
                      <a href={link.link} className="ml-4 font-medium">
                        {link.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="pb-2 pt-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;
