import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  AtSymbolIcon,
  CalendarIcon,
  MenuIcon,
  PuzzleIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  HeartIcon,
  XIcon,
  BookOpenIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ReactComponent as Logo } from "../img/logo.svg";

const navLinks = [
  {
    name: "Colaboradores",
    description: "Nos apoyan distintas comunidades, conócelas.",
    href: "/colaboradores",
    icon: HeartIcon,
  },
  {
    name: "Actividades",
    description: "Descubre todo lo que puedes realizar en la comunidad.",
    href: "/actividades",
    icon: PuzzleIcon,
  },
  {
    name: "Redes Sociales",
    description: "Síguenos en nuestras redes y mantente actualizado de nuestras actividades hechas para ti.",
    href: "/redes-sociales",
    icon: AtSymbolIcon,
  },
  {
    name: "Preguntas Frecuentes",
    description: "¿Tienes dudas? Encuentra las respuestas en esta sección.",
    href: "/preguntas",
    icon: QuestionMarkCircleIcon,
  },
  {
    name: "Código de Conducta",
    description: "Mantenemos un entorno saludable y de respeto en la comunidad.",
    href: "/codigo-de-conducta",
    icon: ShieldCheckIcon,
  },
  {
    name: "Eventos",
    description: "Descubre todos los eventos que tenemos organizados para ti.",
    href: "/eventos",
    icon: CalendarIcon,
  },
  {
    name: "Recursos",
    description: "Accede a la bibiloteca de recursos para nuestras actividades.",
    href: "/recursos",
    icon: BookOpenIcon,
  },
];

const aboutMenu = [navLinks[0], navLinks[1], navLinks[2]];
const moreMenu = [navLinks[3], navLinks[4]];
const mainMobileMenu = [navLinks[5], navLinks[6], navLinks[2]];
const secondaryMobileMenu = [navLinks[3], navLinks[0], navLinks[1], navLinks[4]];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <Popover className="sticky bg-gray-800 top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-900 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <span className="sr-only">Lambda Club</span>
              <Logo className="fill-current text-white h-8 w-auto sm:h-10 lg:h-12" />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
              <span className="sr-only">Abrir menú</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-300" : "text-white",
                      "group bg-gray-800 rounded-md inline-flex items-center text-base font-medium hover:text-gray-300 focus:outline-none"
                    )}
                  >
                    <span>Conócenos</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-300" : "text-white",
                        "ml-2 h-5 w-5 group-hover:text-gray-300"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {aboutMenu.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-blue-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <a href={navLinks[5].href} className="text-base font-medium text-white hover:text-gray-300">
              {navLinks[5].name}
            </a>
            <a href={navLinks[6].href} className="text-base font-medium text-white hover:text-gray-300">
            {navLinks[6].name}
            </a>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-300" : "text-white",
                      "group bg-gray-800 rounded-md inline-flex items-center text-base font-medium hover:text-gray-300 focus:outline-none"
                    )}
                  >
                    <span>Más</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-300" : "text-white",
                        "ml-2 h-5 w-5 group-hover:text-gray-300"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {moreMenu.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-blue-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <button
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 opacity-75 cursor-not-allowed"
              disabled
            >
              Próximamente
            </button>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <a href="/">
                  <Logo className="fill-current text-gray-800 h-9 w-auto" />
                </a>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Cerrar menú</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {mainMobileMenu.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon className="flex-shrink-0 h-6 w-6 text-blue-600" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {secondaryMobileMenu.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div>
                <button
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 opacity-75 cursor-not-allowed"
                  disabled
                >
                  Próximamente
                </button>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
