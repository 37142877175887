import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Welcome from "./components/Welcome";
import Collaborators from "./components/Collaborators";
import Activities from "./components/Activities";
import SocialNetworks from "./components/SocialNetworks";
import Agenda from "./components/Agenda";
import Resources from "./components/ResourcesLibrary";
import FAQ from "./components/FAQ";
import ConductCode from './components/ConductCode';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
        <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="colaboradores/*" element={<Collaborators />} />
          <Route path="actividades/*" element={<Activities />} />
          <Route path="redes-sociales/*" element={<SocialNetworks />} />
          <Route path="eventos/*" element={<Agenda />} />
          <Route path="recursos/*" element={<Resources />} />
          <Route path="preguntas/*" element={<FAQ />} />
          <Route path="codigo-de-conducta/*" element={<ConductCode />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
