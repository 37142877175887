import React from "react";
import {ReactComponent as Logo} from "../img/logo-alt.svg";

export default function Welcome() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 px-10 md:px-14 xl:px-20  items-center bg-gradient-to-b from-blue-400 to-blue-700 flex-auto">
      <Logo className="fill-current text-white w-2/3 justify-self-center self-end md:self-center mb-4 md:mb-0"/>
      <p className="text-white font-medium text-center md:text-left text-2xl self-start md:self-center sm:text-3xl lg:text-4xl mt-4 md:mt-0">
        <strong>Lambda Club</strong> es un espacio construido para estudiantes, con la finalidad de participar en <strong>actividades
        educativas</strong> y brindar <strong>oportunidades de crecimiento</strong> en el área tecnológica
      </p>
    </div>
  );
}
