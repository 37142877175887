import React from "react";
import { ReactComponent as Eventbrite } from "../img/eventbrite.svg";
import { ReactComponent as Facebook } from "../img/facebook.svg";
import { ReactComponent as Discord } from "../img/discord.svg";

export default function SocialNetworks() {
  return (
    <div className="flex flex-col flex-auto px-10 md:px-14 xl:px-20 bg-gray-200 xl:py-10">
      <p className="text-center text-3xl sm:text-6xl mt-5">¡Únete a nuestra comunidad!</p>
      <p className="text-center text-lg sm:text-2xl mt-5">
        Síguenos en nuestras redes y mantente actualizado de nuestras actividades hechas para ti
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-center mt-10 sm:mt-16">
        <a
          href="https://discord.gg/FsDtbxgECX"
          className="w-min flex flex-col hover:bg-gray-300 px-4 py-6 rounded-2xl place-self-center"
        >
          <Discord className="w-auto max-h-32" />
          <p className="text-center text-xl sm:text-2xl mt-5">Discord</p>
        </a>
        <a
          href="https://www.facebook.com/lambdaclubmx/"
          className="w-min flex flex-col hover:bg-gray-300 px-6 py-6 rounded-2xl place-self-center"
        >
          <Facebook className="w-auto max-h-32" />
          <p className="text-center text-xl sm:text-2xl mt-5">Facebook</p>
        </a>
        <a
          href="http://lambda-club.eventbrite.com/"
          className="w-min flex flex-col hover:bg-gray-300 px-6 py-6 rounded-2xl place-self-center"
        >
          <Eventbrite className="w-auto max-h-32" />
          <p className="text-center text-xl sm:text-2xl mt-5">Eventbrite</p>
        </a>
      </div>
    </div>
  );
}
