import React from "react";
import { AcademicCapIcon, StarIcon, ChatIcon, MoonIcon } from "@heroicons/react/solid";

export default function Activities() {
  return (
    <div className="flex flex-col flex-auto px-10 md:px-14 xl:px-20 bg-gray-200 py-5 xl:py-10">
      <p className="text-center text-3xl sm:text-6xl mt-5">Actividades para todos</p>
      <p className="text-center text-lg sm:text-2xl mt-5">
        ¡Mira todo lo que puedes realizar en la comunidad!
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 items-center mt-10 sm:mt-16 gap-y-4 sm:gap-y-16">
        <div className="w-max flex flex-col hover:bg-gray-300 px-4 py-6 rounded-2xl place-self-center">
          <AcademicCapIcon className="h-20 text-cyan-500" />
          <p className="text-center text-xl sm:text-2xl mt-5 font-medium">Cursos</p>
          <p className="text-center sm:text-lg mt-2 max-w-xs lg:max-w-md">
            Múltiples cursos para desarrollar tus conocimientos y habilidades sociales/técnicas.
          </p>
        </div>
        <div className="w-max flex flex-col hover:bg-gray-300 px-4 py-6 rounded-2xl place-self-center">
          <StarIcon className="h-20 text-cyan-500" />
          <p className="text-center text-xl sm:text-2xl mt-5 font-medium">Invitados especiales</p>
          <p className="text-center sm:text-lg mt-2 max-w-xs lg:max-w-md">
            Conferencistas invitados con experiencia profesional, para enriquecer e inspirar a los
            estudiantes.
          </p>
        </div>
        <div className="w-max flex flex-col hover:bg-gray-300 px-4 py-6 rounded-2xl place-self-center">
          <ChatIcon className="h-20 text-cyan-500" />
          <p className="text-center text-xl sm:text-2xl mt-5 font-medium">Charlas estudiantiles</p>
          <p className="text-center sm:text-lg mt-2 max-w-xs lg:max-w-md">
            Todos los miembros en la comunidad tienen la oportunidad para realizar una plática/taller.
          </p>
        </div>
        <div className="w-max flex flex-col hover:bg-gray-300 px-4 py-6 rounded-2xl place-self-center">
          <MoonIcon className="h-20 text-cyan-500" />
          <p className="text-center text-xl sm:text-2xl mt-5 font-medium">Noches Sociales</p>
          <p className="text-center sm:text-lg mt-2 max-w-xs lg:max-w-md">
            Un espacio en donde podrás conectar y conocer a otros miembros de la comunidad.
          </p>
        </div>
      </div>
    </div>
  );
}
