import React, { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import faqs from "../data/faqs";

export default function FAQ() {
  return (
    <div className="flex flex-col px-10 md:px-14 xl:px-20 justify-start items-start bg-gray-200 flex-auto">
      {faqs.map(({ question, answer }, index) => (
        <Disclosure key={index}>
          {({ open }) => (
            <Fragment>
              <Disclosure.Button
                className={`py-3 bg-white w-full text-left px-6 text-lg rounded-t-lg font-semibold mt-4 ${
                  open ? "" : "rounded-b-lg"
                }`}
              >
                <div className="flex flex-row items-center justify-start">
                  <p className="text-lg flex-grow">{question}</p>
                  <div className="flex-none ml-2 h-6 w-6 text-blue-600">
                    {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </div>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className=" text-lg text-gray-600 font-medium w-full px-6 py-2 rounded-b-lg bg-gray-50">
                {answer}
              </Disclosure.Panel>
            </Fragment>
          )}
        </Disclosure>
      ))}
    </div>
  );
}
